import { TextInput } from 'carbon-components-react';
import { useState } from 'react';

interface CustomerContactEmailInputTextProps{
  onChange: (emailAddress: string) => void
}

function CustomerContactEmailInputText({ onChange }:CustomerContactEmailInputTextProps){
  const [customerEmailAddress, setCustomerEmailAddress] = useState('');
  const [isValidEmailAddress, setIsValidEmailAddress] = useState(true);
  const [invalidText, setInvalidText] = useState('');
  function validateEmailAddress(emailAddress:string){
    setCustomerEmailAddress(emailAddress);
    setIsValidEmailAddress(true);
    if (emailAddress.trim().length === 0){
      setIsValidEmailAddress(false);
      setInvalidText('A customer contact email is required');
    } else if (emailAddress.toLowerCase().match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)){
      setIsValidEmailAddress(true);
    } else {
      setIsValidEmailAddress(false);
      setInvalidText('The customer contact must be an email address');
    }
    onChange(emailAddress);
  }
  return (
    <TextInput
      id="customer-contact-email"
      name="contactEmail"
      labelText=""
      placeholder="customer@domain.com"
      value={customerEmailAddress}
      invalid={!isValidEmailAddress}
      invalidText={invalidText}
      autoFocus
      onChange={(e) => { validateEmailAddress(e.target.value); }}
      onBlur={(e) => { validateEmailAddress(e.target.value); }}
    />
  );
}

export default CustomerContactEmailInputText;
