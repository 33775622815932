import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Checkbox, TooltipDefinition } from 'carbon-components-react';
import { useParams } from 'react-router-dom';
import { postIdeaComment, subscribeToIdea } from '../../utils/api';
import { IdeaComment } from '../../models/idea';
import { useUserContext } from '../../context/UserContext';
import { useToastContext } from '../../context/ToastContext';
import { useCommentContext } from '../../context/CommentContext';
import { useSubscribeContext } from '../../context/SubscribeContext';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { CommentVisibility } from '../../utils/commentVisibility';
import styles from './CommentBox.module.css';
import FileUpload from '../FileUpload/FileUpload';
import { SelectedFile } from '../../models/file';

interface CommentBoxProps {
  comment?: IdeaComment;
  setChildrenComments?: Dispatch<SetStateAction<IdeaComment[]>>;
  isReply?: boolean;
}

function CommentBox({ comment, setChildrenComments, isReply = false }: CommentBoxProps) {
  const { ideaId } = useParams();
  const { addToast } = useToastContext();
  const { user, isIBMEmployee } = useUserContext();
  const { setCommentId, commentCount, setCommentCount } = useCommentContext();
  const { isSubscribed, setIsSubscribed } = useSubscribeContext();
  const [commentText, setComment] = useState<string>('');
  const [isCommenting, setIsCommenting] = useState(false);
  const [sharedPrivately, setSharedPrivately] = useState(false);
  const [selectSubscribe, setSelectSubscribe] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const [fileUploaderror, setfileUploaderror] = useState(false);
  if (!ideaId || !user) { return null; }

  let placeholderText = 'Add a comment';
  let buttonText = 'Post Comment';
  if (isReply) {
    placeholderText = 'Reply to comment';
    buttonText = 'Post Reply';
  }
  const disableButton = commentText === '' || isCommenting;

  const getComment = (commentID: string, visibility: string | null, parentID: string | null): IdeaComment => ({
    user_type: 'user_type',
    user_name: 'You',
    user_email: user.email,
    comment_id: commentID,
    comment_created_at: new Date().toISOString(),
    comment: commentText,
    comment_visibility: visibility,
    comment_parent_id: parentID,
    children_comments: null,
    ibm_employee: isIBMEmployee,
    attachments: []
  });

  const checkReplyChecked = (!!(isReply && comment && comment.comment_visibility === CommentVisibility.commentCreator) || sharedPrivately);

  const getCommentVisibility = checkReplyChecked ? CommentVisibility.commentCreator : CommentVisibility.public;

  const getParentID = () => (isReply && comment ? comment.comment_id : null);

  const setNewReply = (newComment: IdeaComment) => {
    if (isReply && setChildrenComments) {
      setChildrenComments((prevChildComments) => [...prevChildComments, newComment]);
    }
  };

  const isFileUploadValid = () => {
    const areAllFilesUploaded = selectedFiles.every((file) => file.status === 'edit');
    if (!areAllFilesUploaded) setfileUploaderror(true);
  };

  const subscribe = async () => {
    setIsSubscribed(true);
    try {
      await subscribeToIdea(ideaId);
      addToast({
        kind: 'success',
        title: 'You have been Subscribed to the idea',
      });
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to subscribe to the idea',
        message: 'Something went wrong, please try again later',
      });
      setIsSubscribed(false);
    }
  };

  const onPostClick = async () => {
    setIsCommenting(true);
    isFileUploadValid();
    if (!fileUploaderror){
      const visibilityParam = checkReplyChecked ? 'private' : 'public';
      const parentID = getParentID();
      const fileIds = selectedFiles.map((file) => file.id) as string[];
      const params: { [key: string]: any } = {
        comment: commentText,
        ...(visibilityParam && { comment_visibility: visibilityParam }),
        ...(parentID && { comment_parent_id: parentID }),
        ...(fileIds && { comment_file_ids: selectedFiles.map((file) => file.id) as string[] })
      };

      if (selectSubscribe) subscribe();

      postIdeaComment(ideaId, params)
        .then((response) => {
          const newComment = getComment(response.data.comment_id, getCommentVisibility, parentID);
          setCommentCount(commentCount + 1);
          if (!isReply) {
            setCommentId(response.data.comment_id);
          }
          setNewReply(newComment);
          setComment('');
          setSharedPrivately(false);
          let title = 'Your comment has been added';
          if (isReply) {
            title = 'Your reply has been added';
          }
          addToast({ kind: 'success', title });
        }).catch(() => {
          let title = 'Failed to post a comment on the idea';
          if (isReply) {
            title = 'Failed to post a reply to the comment';
          }
          addToast({
            kind: 'error',
            title,
            message: 'Something went wrong, please try again later',
          });
        }).finally(() => {
          setIsCommenting(false);
          setSelectSubscribe(false);
          setSelectedFiles([]);
        });
    } else {
      setIsCommenting(false);
    }
  };

  const selectTooltipText = () => {
    if (isReply && comment && comment.comment_visibility === CommentVisibility.commentCreator) {
      return 'Your reply will be visible only to IBM';
    }
    if (isReply && comment && comment.comment_visibility === CommentVisibility.public) {
      return 'Your reply will be visible to all other users';
    }
    return 'If checked, your comment will be visible only to IBM';
  };

  return (
    <div className={styles.commentBox}>
      <RichTextEditor
        className={styles.richTextEditor}
        data={commentText}
        placeholder={placeholderText}
        onChange={setComment}
      />
      <div className={styles.fileUplaod}>
        <FileUpload
          selectedFiles={selectedFiles}
          updateSelectedFiles={setSelectedFiles}
        />
        {fileUploaderror && (
        <p className={styles.formError}>Please wait until all files have uploaded</p>
        )}
      </div>
      <div className={styles.commentButton}>
        <div className={styles.commentCheckBox}>
          <TooltipDefinition tooltipText={selectTooltipText()}>
            <Checkbox
              id={`select-share-privately-${getParentID()}`}
              labelText="Share privately with IBM"
              checked={checkReplyChecked}
              onChange={(value: boolean) => setSharedPrivately(value)}
              disabled={isReply}
            />
          </TooltipDefinition>
          {isSubscribed ? (
            <TooltipDefinition tooltipText="You are already subscribed to this idea">
              <Checkbox
                id={`select-subscribe-to-idea-${getParentID()}`}
                labelText="Subscribe to idea"
                checked={selectSubscribe}
                onChange={(value: boolean) => setSelectSubscribe(value)}
                disabled
              />
            </TooltipDefinition>
          ) : (
            <Checkbox
              id={`select-subscribe-to-idea-${getParentID()}`}
              labelText="Subscribe to idea"
              checked={selectSubscribe}
              onChange={(value: boolean) => setSelectSubscribe(value)}
            />
          )}
        </div>
        <div className={styles.postButton}>
          <Button size="sm" onClick={onPostClick} disabled={disableButton}>{buttonText}</Button>
        </div>
      </div>
    </div>
  );
}

export default CommentBox;
