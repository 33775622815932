import styles from './AttachmentField.module.css';

interface AttachmentFieldProps{
  label:string;
  value:string;
}

function AttachmentField({ label, value }:AttachmentFieldProps) {
  const onDownloadAttachment = (downloadUrl: string) => {
    window.open(downloadUrl, '_blank');
  };
  const validFormat = ['csv', 'doc', 'docx', 'pdf', 'ppt', 'pptx', 'txt', 'xls', 'xlsx', 'mp4', 'mov'];
  const validateImageFormat = (fileName:string) => {
    const regex = /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i;
    return regex.test(fileName);
  };
  const attachmentformat = validFormat.includes(label.split('.')[1]) ? styles[`attachment_${label.split('.')[1]}`] : styles.attachment_default;

  return (
    <div className={styles.attachmentContainer}>
      <img role="presentation" alt={label} className={`${validateImageFormat(label) ? styles.attachmentSvg : `${attachmentformat} ${styles.attachmentSvg}`}`} src={value} onClick={() => onDownloadAttachment(value)} />
      <div className={styles.attachemntCaption}>{label}</div>
    </div>
  );
}

export default AttachmentField;
