import { TooltipIcon } from 'carbon-components-react';
import { Close } from '@carbon/icons-react';
import CustomerContactEmailInputText from './CustomerContactEmailInputText';
import styles from './CustomerContactEmail.module.css';

interface CustomerContactEmailRowProps {
  onChange: (customerEmailAddress: string) => void,
  onRemove: () => void,
  isRemoveDisabled: boolean,
}

interface RemoveBtnProps {
  onClick: () => void,
  disabled: boolean,
}

function RemoveBtn({ onClick, disabled }: RemoveBtnProps) {
  return (
    <TooltipIcon
      className={styles.removeBtn}
      tooltipText="Remove Email"
      direction="right"
      onClick={() => onClick()}
      disabled={disabled}
    >
      <Close />
    </TooltipIcon>
  );
}

function CustomerContactEmailRow({ onChange, onRemove, isRemoveDisabled }:CustomerContactEmailRowProps){
  const onCustomerEmailAddressChange = (customerEmailStr: string) => {
    onChange(customerEmailStr);
  };
  return (
    <div className={styles.CustomerEmailInputRow}>
      <div className={styles.inputRow}>
        <CustomerContactEmailInputText
          onChange={onCustomerEmailAddressChange}
        />
        <RemoveBtn onClick={onRemove} disabled={isRemoveDisabled} />
      </div>
    </div>
  );
}
export default CustomerContactEmailRow;
