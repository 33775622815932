import { useState } from 'react';
import { Add, Checkmark, Subtract } from '@carbon/icons-react';
import { InlineLoading, OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import { useGroupsContext } from '../../context/GroupsContext';
import styles from '../IdeaCard/IdeaCard.module.css';
import { Idea } from '../../models/idea';
import { Group } from '../../models/group';
import { addIdeasToGroup, removeIdeasFromGroup } from '../../utils/api';
import { useToastContext } from '../../context/ToastContext';

interface AddToGroupProps{
  idea:Idea
}

function AddToGroup({ idea }:AddToGroupProps){
  const { groups, fetchGroups } = useGroupsContext();
  const [isAddingToGroup, setIsAddingToGroup] = useState(false);
  const [isRemovingFromGroup, setIsRemovingFromGroup] = useState(false);
  const { addToast } = useToastContext();

  const removeFromGroup = async (groupId:string, ideaId:string) => {
    setIsRemovingFromGroup(true);

    try {
      await removeIdeasFromGroup(groupId, [ideaId]);

      addToast({
        kind: 'success',
        title: 'Idea removed from group',
      });
      // Update locally so that the group is ticked in the dropdown
      const newSharedGroup = idea.shared_groups?.filter((grp) => grp !== groupId);
      idea.shared_groups = newSharedGroup || [];
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to remove idea from group',
        message: 'Something went wrong, please try again later'
      });
    }
    await fetchGroups();
    setIsRemovingFromGroup(false);
  };

  const addToGroup = async (group: Group) => {
    setIsAddingToGroup(true);

    try {
      await addIdeasToGroup(group.id, [idea.id]);
      addToast({
        kind: 'success',
        title: 'Idea added to group',
        message: <>The idea has been added to the group <em>{group.name}</em></>
      });

      // Update locally so that the group is ticked in the dropdown
      if (!idea.shared_groups) {
        idea.shared_groups = [];
      }
      idea.shared_groups.push(group.id);
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to add idea to group',
        message: 'Something went wrong, please try again later'
      });
    }

    await fetchGroups();
    setIsAddingToGroup(false);
  };

  return (
    <div className={styles.groupBtnsContainer}>
      {groups.length !== 0 && (
        isAddingToGroup ? (
          <InlineLoading className={styles.iconButtonSpinner} />
        ) : (
          <OverflowMenu
            className={`${styles.addToGroupBtn} bx--tooltip__trigger bx--tooltip--icon__top`}
            menuOptionsClass={styles.addToGroupMenu}
            ariaLabel="Add to group"
            iconDescription="Add to group"
            flipped
            size="sm"
            onClick={(e) => e.preventDefault()}
            renderIcon={Add}
          >
            {groups.map((group) => (
              <OverflowMenuItem
                key={group.id}
                itemText={(
                  <div className={styles.groupOption} title={group.name}>
                    {idea.shared_groups && idea.shared_groups.includes(group.id) && (
                    <Checkmark />
                    )}
                    {group.name}
                  </div>
              )}
                onClick={() => addToGroup(group)}
                disabled={idea.shared_groups ? idea.shared_groups.includes(group.id) : false}
              />
            ))}
          </OverflowMenu>
        )
      )}
      {groups.filter((group) => idea.shared_groups?.includes(group.id)).length > 0 && (
        isRemovingFromGroup ? (
          <InlineLoading className={styles.iconButtonSpinner} />
        ) : (
          <OverflowMenu
            className={`${styles.addToGroupBtn} bx--tooltip__trigger bx--tooltip--icon__top`}
            menuOptionsClass={styles.addToGroupMenu}
            ariaLabel="Remove from group"
            iconDescription="Remove from group"
            flipped
            size="sm"
            onClick={(e) => e.preventDefault()}
            renderIcon={Subtract}
          >
            {groups.filter((group) => idea.shared_groups?.includes(group.id)).map((group) => (
              <OverflowMenuItem
                key={group.id}
                itemText={(
                  <div className={styles.groupOption} title={group.name}>
                    {idea.shared_groups && idea.shared_groups.includes(group.id) && (
                    <Checkmark />
                    )}
                    {group.name}
                  </div>
                )}
                onClick={() => removeFromGroup(group.id, idea.id)}
              />
            ))}
          </OverflowMenu>
        )
      )}
    </div>
  );
}
export default AddToGroup;
