import { useRef, useState } from 'react';
import { Button } from 'carbon-components-react';
import { Add } from '@carbon/icons-react';
import CustomerContactEmailRow from './CustomerContactEmailRow';
import { EmailAddress } from '../../models/emailAddress';

interface CustomerContactEmailInputProp{
  labelText: React.ReactNode,
  onChange: (customerEmail: EmailAddress[]) => void,
}

function CustomerContactEmailInput({ labelText, onChange }:CustomerContactEmailInputProp){
  const lastCustomerEmailAddress = useRef(0);
  const getNextCustomerEmailId = () => {
    lastCustomerEmailAddress.current += 1;
    return lastCustomerEmailAddress.current;
  };

  const [customerEmailItems, setCustomerEmailItems] = useState<{ id: number, customerEmailAddress: string }[]>([{ id: getNextCustomerEmailId(), customerEmailAddress: '' }]);

  const removeCustomerEmailInput = (id: number) => {
    setCustomerEmailItems((prevCustomerEmailItems) => {
      const filteredItems = prevCustomerEmailItems.filter((thisCustomerEmailItem) => thisCustomerEmailItem.id !== id);
      if (filteredItems.length === 0) {
        filteredItems.push({ id: getNextCustomerEmailId(), customerEmailAddress: '' });
      }
      onChange(filteredItems);
      return filteredItems;
    });
  };

  return (
    <>
      <label htmlFor="contactEmail" className="bx--label">{labelText}</label>
      <div>
        {customerEmailItems.map((customerEmailItem) => (
          <CustomerContactEmailRow
            key={customerEmailItem.id}
            onChange={(newValue: any) => {
              setCustomerEmailItems((prevCustomerItems) => prevCustomerItems.map((c) => (c.id === customerEmailItem.id ? { id: c.id, customerEmailAddress: newValue } : c)));
              onChange(customerEmailItems);
            }}
            onRemove={() => removeCustomerEmailInput(customerEmailItem.id)}
            isRemoveDisabled={customerEmailItems.length === 1}
          />
        ))}
      </div>
      <div>
        <Button
          kind="tertiary"
          size="sm"
          renderIcon={Add}
          onClick={() => setCustomerEmailItems((prevCustomerEmailItems) => [...prevCustomerEmailItems, { id: getNextCustomerEmailId(), customerEmailAddress: '' }])}
          disabled={customerEmailItems.length === 0 || !customerEmailItems[customerEmailItems.length - 1].customerEmailAddress}
        >
          Add another Email
        </Button>
      </div>
    </>
  );
}

export default CustomerContactEmailInput;
