import { Copy, ViewOff } from '@carbon/icons-react';
import { Button, ButtonSet, Checkbox, MultiSelect, Dropdown, InlineLoading, InlineNotification, Link, RadioButton, RadioButtonGroup, TextInput, TooltipIcon, FilterableMultiSelect, FormGroup } from 'carbon-components-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FileUpload from '../../components/FileUpload/FileUpload';
import ProductDropdown, { ProductCategoryItem } from '../../components/ProductDropdown/ProductDropdown';
import { SelectedFile } from '../../models/file';
import { NewIdea as NewIdeaModel } from '../../models/idea';
import { Category, Product, ProductCategory } from '../../models/product';
import { addIdea, addIdeasToGroup, getProduct, getProductCategories } from '../../utils/api';
import styles from './NewIdea.module.css';
import { Group } from '../../models/group';
import { useFlashContext } from '../../context/FlashContext';
import { useGroupsContext } from '../../context/GroupsContext';
import { useToastContext } from '../../context/ToastContext';
import { useUserContext } from '../../context/UserContext';
import Tag from '../../components/Tag/Tag';
import { useCustomFieldsContext } from '../../context/CustomFieldsContext';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import CompanyInput from '../../components/CompanyInput/CompanyInput';
import { CompanyIn } from '../../models/company';
import CustomerContactEmailInput from '../../components/CustomerContactEmailInput/CustomerContactEmailInput';
import { EmailAddress } from '../../models/emailAddress';

const IDEA_PRIORITY_OPTIONS = [
  { id: 'low',    text: 'Low' },
  { id: 'medium', text: 'Medium' },
  { id: 'high',   text: 'High' },
  { id: 'urgent', text: 'Urgent' },
];

const NEEDED_BY_OPTIONS = [
  { id: 'yesterday', text: 'Yesterday (let\'s go already!)' },
  { id: 'week',      text: 'Week' },
  { id: 'month',     text: 'Month' },
  { id: 'quarter',   text: 'Quarter' },
  { id: 'not_sure',  text: 'Not sure - just thought it was cool' },
];

function generateNewIdeaUrl(productId?: string, categoryId?: string) {
  const params = new URLSearchParams();

  if (productId) {
    params.append('product', productId);
    if (categoryId) {
      params.append('category', categoryId);
    }
  }

  return `${window.location.origin}/new-idea?${params.toString()}`;
}

function NewIdea() {
  const { addToast } = useToastContext();
  const { setNewIdeaId } = useFlashContext();
  const { isLoadingGroups, groups } = useGroupsContext();
  const { isIBMEmployee } = useUserContext();
  const { managedTags, classificationThemes } = useCustomFieldsContext();
  const hiddenManagedTags = ['Blocker to Deploy', 'Blocker to Sell', 'Blocker for Digital'];
  const visibleManagedTags = managedTags.filter((tag) => !hiddenManagedTags.includes(tag));
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);

  const [selectedProductCategoryItem, setSelectedProductCategoryItem] = useState<ProductCategoryItem | null>(null);
  const selectedProduct = selectedProductCategoryItem?.product || null;

  const [formError, setFormError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ProductCategory | null>(null);

  const [ibmManagedTags, setIBMManagedTags] = useState<string[]>([]);
  const [selectedClassificationThemes, setSelectedClassificationThemes] = useState<string[]>([]);

  const [associatedCompanies, setAssociatedCompanies] = useState<CompanyIn[]>([]);
  const [customerEmailAddress, setCustomerEmailAddress] = useState<string>('');

  const [values, setValues] = useState({
    raisedFor: 'myself',
    contactEmail: '',
    visibility: isIBMEmployee ? 'private' : 'public',
    name: '',
    description: '',
    additionalDetails: '',
    company: '',
    supportCaseId: '',
    priority: IDEA_PRIORITY_OPTIONS[0].id,
    neededBy: 'not_sure',
    impactStatement: '',
    issueTrackerCandidate: false,
    subscribeCreator: true,
    agreeToTerms: false,
    blockerToSell: false,
    blockerToDeploy: false,
    blockerForDigital: false
  });

  const selectedNeededBy = NEEDED_BY_OPTIONS.find((option) => option.id === values.neededBy);

  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);

  const [errors, setErrors] = useState<{ [key: string]: string | null }>({});

  const setValue = (name: string, value: string) => {
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const setError = <T,>(name: string, fn: (v: T) => string | null) => (value: T) => {
    const error = fn(value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    return !error;
  };

  const isProductValid = setError('product', (product: Product | null) => {
    if (!product) return 'A product must be selected';
    return null;
  });

  const isCategoryValid = setError('category', (category: ProductCategory | null) => {
    if (isLoadingCategories) return 'Please wait for product categories to load';
    if (categories.length !== 0 && !category) return 'A category must be selected';
    return null;
  });

  const isFileUploadValid = setError('fileUpload', () => {
    const areAllFilesUploaded = selectedFiles.every((file) => file.status === 'edit');
    if (!areAllFilesUploaded) return 'Please wait until all files have uploaded';
    return null;
  });

  const isNeededByValid = setError('neededBy', (neededBy: string) => {
    if (!neededBy) return 'Please choose an option';
    return null;
  });

  const isCompanyValid = setError('company', (companies: CompanyIn[]) => {
    if (companies.length === 0) return 'A company is required';
    return null;
  });

  const isAgreeToTermsValid = setError('agreeToTerms', (value: boolean) => {
    if (!value) return 'You must agree to the IBM Ideas Portal Terms of Use';
    return null;
  });

  const isTextValid: { [key: string]: (value: string) => boolean } = useMemo(() => ({
    contactEmail: setError('contactEmail', (value: string) => {
      if (values.raisedFor === 'customer' && value.length === 0) {
        return 'A customer contact email is required';
      }
      const inValidEmail = value.split(',').filter((val) => !val.toLowerCase().match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/));

      if (values.raisedFor === 'customer' && inValidEmail && inValidEmail.length !== 0){
        return 'The customer contact must be an email address';
      }
      return null;
    }),
    name: setError('name', (value: string) => {
      if (!value) return 'An idea title is required';
      if (value.length < 3) return 'The idea title must be at least 3 characters';
      return null;
    }),
    description: setError('description', (value: string) => {
      if (!value) return 'An idea description is required';
      return null;
    }),
    additionalDetails: setError('additionalDetails', () => null),
    supportCaseId: setError('supportCaseId', (value: string) => {
      const supportCaseIdRegex = /^[a-zA-Z]{2}\d{9}$/;

      if (value.length !== 0 && !supportCaseIdRegex.test(value)) {
        return 'The support case number must be in the format XX000000000';
      }

      return null;
    }),
    impactStatement: setError('impactStatement', (value: string) => {
      if (!value) return 'An impact statement is required';
      return null;
    }),
  }), [values]);

  const getCategories = async (product: Product, category: Category | null) => {
    setIsLoadingCategories(true);
    setSelectedCategory(null);

    const { data } = await getProductCategories(product.id);

    setCategories(data);

    if (category) {
      const foundCategory = data.find((cat) => cat.id === category.id);
      setSelectedCategory(foundCategory || null);
    }

    setIsLoadingCategories(false);
  };

  const onProductCategoryItemChange = (newSelectedProductCategoryItem: ProductCategoryItem | null) => {
    setSelectedProductCategoryItem(newSelectedProductCategoryItem);

    setSelectedCategory(null);
    setErrors((prevErrors) => ({ ...prevErrors, category: null }));

    if (newSelectedProductCategoryItem) {
      const { product, category } = newSelectedProductCategoryItem;
      isProductValid(product);

      getCategories(product, category);
    } else {
      isProductValid(null);
      setCategories([]);
    }
  };

  const queryProductId = searchParams.get('product');
  const queryCategoryId = searchParams.get('category');

  useEffect(() => {
    if (!queryProductId) return;

    const fetchProduct = async () => {
      getProduct(queryProductId).then(({ data }) => {
        onProductCategoryItemChange({
          product: data,
          category: (queryCategoryId === null) ? null : { id: queryCategoryId, name: 'Unknown' }
        });
      }).catch(() => {
        addToast({
          kind: 'warning',
          title: 'Product not found',
          message: 'Manually search for a product'
        });
      });
    };
    fetchProduct();
  }, [queryProductId, queryCategoryId]);

  const getCategoryLabel = () => {
    if (selectedProductCategoryItem === null) return '';
    if (isLoadingCategories) return 'Loading categories...';
    if (categories.length === 0) return 'No categories in the selected product';
    return 'Select a category';
  };

  const getGroupLabel = () => {
    if (isLoadingGroups) return 'Loading shared groups...';
    return 'Shared groups';
  };

  const onFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setValue(name, value);
    isTextValid[name](value);
  };

  const isFormValid = () => {
    const validFields = [
      isProductValid(selectedProduct),
      isCategoryValid(selectedCategory),
      isTextValid.name(values.name),
      isTextValid.description(values.description),
      isFileUploadValid(null),
      isCompanyValid(associatedCompanies),
      isTextValid.supportCaseId(values.supportCaseId),
      isNeededByValid(values.neededBy),
      isAgreeToTermsValid(values.agreeToTerms),
    ];
    if (isIBMEmployee) {
      validFields.push(
        isTextValid.contactEmail(customerEmailAddress as unknown as string),
        isTextValid.impactStatement(values.impactStatement),
      );
    } else {
      validFields.push(
        isTextValid.additionalDetails(values.additionalDetails),
      );
    }

    return validFields.every((isValid) => isValid);
  };

  const onAddClick = async () => {
    if (!isFormValid()) {
      setFormError('Please fix the above errors');
      return;
    }

    if (!selectedProduct) return;

    setFormError(null);
    setIsSubmitting(true);

    const fileIds = selectedFiles.map((file) => file.id) as string[];

    try {
      const newIdeaData: NewIdeaModel = {
        contact_email: customerEmailAddress,
        visibility: values.visibility,
        instance_name: selectedProduct.instance_name,
        product_id: selectedProduct.id,
        category: selectedCategory && selectedCategory.name,
        name: values.name,
        description: values.description,
        private_description: values.additionalDetails,
        file_ids: fileIds,
        company_associations: associatedCompanies,
        support_case_id: values.supportCaseId,
        priority: values.priority,
        subscribe_creator: values.subscribeCreator,
      };

      if (selectedProduct.hide_needed_by !== true) {
        newIdeaData.needed_by = values.neededBy;
      }

      if (isIBMEmployee) {
        const newManagedTags = [...ibmManagedTags];
        if (values.blockerToSell) newManagedTags.push('Blocker to Sell');
        if (values.blockerToDeploy) newManagedTags.push('Blocker to Deploy');
        if (values.blockerForDigital) newManagedTags.push('Blocker for Digital');
        if (newManagedTags.length > 0) {
          newIdeaData.ibm_wide_managed_tags = newManagedTags;
        }

        if (values.issueTrackerCandidate && selectedProduct.is_in_issue_tracker === true) {
          newIdeaData.is_issue_tracker_candidate = true;
        }

        if (classificationThemes.length > 0) {
          newIdeaData.classification_themes = selectedClassificationThemes;
        }

        if (values.impactStatement) {
          newIdeaData.impact_statement = values.impactStatement;
        }
      }
      const { data: newIdea } = await addIdea(newIdeaData);

      setNewIdeaId(newIdea.id);
      for (const groupId of selectedGroupIds) {
        // eslint-disable-next-line no-await-in-loop
        await addIdeasToGroup(groupId, [newIdea.id]);
      }

      addToast({
        kind: 'success',
        title: 'Your new idea has been added'
      });

      window.scrollTo(0, 0);
      navigate('/');
    } catch (err) {
      setFormError('Something went wrong, please try again later');
      setIsSubmitting(false);
    }
  };

  const hiddenIcon = values.visibility === 'public' ? (
    <TooltipIcon
      className={styles.hiddenIcon}
      tooltipText="This will be visible only to you and IBM"
      direction="top"
    >
      <ViewOff />
    </TooltipIcon>
  ) : null;

  const newIdeaUrl = generateNewIdeaUrl(selectedProduct?.id, selectedCategory?.id);
  const addEmailAddress = (value:EmailAddress[]) => {
    const customerEmailIds = value.map((val) => val.customerEmailAddress).join(',');
    setCustomerEmailAddress(customerEmailIds);
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(newIdeaUrl)
      .then(() => {
        addToast({
          kind: 'success',
          title: 'New idea URL copied to clipboard'
        });
      })
      .catch(() => {
        addToast({
          kind: 'error',
          title: 'Failed to copy new idea URL to clipboard',
          message: 'Something went wrong, please try again later'
        });
      });
  };

  return (
    <>
      <h2>Add a New Idea</h2>
      <section className={styles.formSection}>
        {isIBMEmployee && (
          <>
            <div className={styles.formRow}>
              <RadioButtonGroup
                className={styles.raisedForRadioGroup}
                name="raised-for"
                legendText="Who are you raising this idea for?"
                valueSelected={values.raisedFor}
                onChange={(value: string) => {
                  setValue('raisedFor', value);
                  if (value === 'customer') {
                    setValue('visibility', 'public');
                  }
                }}
              >
                <RadioButton labelText="Myself" value="myself" />
                <RadioButton labelText="Customer" value="customer" />
              </RadioButtonGroup>
            </div>
            {values.raisedFor === 'customer' && (
              <div className={`${styles.formRow} ${styles.shortField}`}>
                <CustomerContactEmailInput
                  labelText={<>Customer contact email {hiddenIcon}</>}
                  onChange={(newCustomerEmailAddress) => {
                    addEmailAddress(newCustomerEmailAddress);
                  }}

                />
              </div>
            )}
          </>
        )}
        <div className={styles.formRow}>
          <RadioButtonGroup
            name="visibility"
            legendText="Idea visibility"
            valueSelected={values.visibility}
            orientation="vertical"
            onChange={(value: string) => setValue('visibility', value)}
          >
            <RadioButton
              labelText="Visible to everyone"
              value="public"
              disabled={values.raisedFor === 'customer'}
            />
            <RadioButton
              labelText={isIBMEmployee ? 'Visible only to IBM' : 'Visible only to you and IBM'}
              value="private"
              disabled={values.raisedFor === 'customer'}
            />
          </RadioButtonGroup>
          {values.raisedFor === 'customer' && (
            <p className={styles.visibilityLimitationInfo}>
              Due to Aha! limitations affecting idea visibility, only public ideas can be raised on behalf of a customer<br />
              If the customer would like their idea to remain private (visible to just them and IBM), please ask that they raise it directly
            </p>
          )}
        </div>
      </section>

      <section className={styles.formSection}>
        <h3>Find your product</h3>
        <div className={styles.formRow}>
          <ProductDropdown
            id="idea-product-combo"
            placeholder="Search for a product"
            titleText="Product"
            selectedProductCategoryItem={selectedProductCategoryItem}
            invalid={!!errors.product}
            invalidText={errors.product}
            onProductCategoryItemChange={onProductCategoryItemChange}
          />
        </div>
        <div className={`${styles.formRow} ${styles.shortField}`}>
          <Dropdown
            id="idea-category"
            titleText="Category"
            label={getCategoryLabel()}
            items={categories}
            itemToString={(category: ProductCategory) => category.name!}
            itemToElement={(category: ProductCategory) => (
              <div style={{ marginLeft: category.depth * 15 }}>{category.name!}</div>
            )}
            invalid={!!errors.category}
            invalidText={errors.category}
            selectedItem={selectedCategory}
            onChange={({ selectedItem }: { selectedItem: ProductCategory }) => {
              setSelectedCategory(selectedItem);
              isCategoryValid(selectedItem);
            }}
            disabled={isLoadingCategories || categories.length === 0}
          />
        </div>

        {isIBMEmployee && selectedProduct && (
          <div className={`${styles.formRow} ${styles.shortField} ${styles.linkField}`}>
            <label htmlFor="link">Use this link to prefill this product {selectedCategory && 'and category'} when creating new ideas</label>
            <div className={styles.inline}>
              <input
                id="link"
                type="text"
                value={newIdeaUrl}
                readOnly
                onClick={(e) => (e.target as HTMLInputElement).select()}
              />
              <Button
                type="button"
                kind="tertiary"
                size="sm"
                renderIcon={Copy}
                onClick={copyLinkToClipboard}
              >
                Copy
              </Button>
            </div>
          </div>
        )}
      </section>

      <section className={styles.formSection}>
        <h3>Tell us about your idea</h3>
        <div className={styles.formRow}>
          <TextInput
            id="idea-name"
            name="name"
            labelText="Your idea"
            placeholder="One sentence summary of the idea"
            invalid={!!errors.name}
            invalidText={errors.name}
            required
            autoComplete="off"
            value={values.name}
            onChange={onFieldChange}
            onBlur={onFieldChange}
          />
        </div>
        <div className={styles.formRow}>
          <div className={styles.richTextEditorWrapper}>
            <label htmlFor="description" className="bx--label">Description</label>
            <RichTextEditor
              className={`${styles.richTextEditor} ${errors.description ? styles.invalid : ''}`}
              data={values.description}
              placeholder="Why is it useful? Who would benefit from it? How should it work?"
              onChange={(newDescription) => { setValue('description', newDescription); isTextValid.description(newDescription); }}
            />
            {errors.description && (
              <div className={styles.formError}>{errors.description}</div>
            )}
          </div>
        </div>
        {isIBMEmployee ? (
          <div className={styles.formRow}>
            <div className={styles.richTextEditorWrapper}>
              <label htmlFor="impact-statement" className="bx--label">
                IBM business impact statement
                <TooltipIcon
                  className={styles.hiddenIcon}
                  tooltipText="This will only be visible to IBM"
                  direction="top"
                >
                  <ViewOff />
                </TooltipIcon>
              </label>
              <RichTextEditor
                className={`${styles.richTextEditor} ${errors.impactStatement ? styles.invalid : ''}`}
                data={values.impactStatement}
                placeholder="Please provide the business impact that this idea will have. Include customer name(s) and if possible its revenue potential. This information will make an impact and is important to prioritize and drive this item forward."
                onChange={(newImpactStatement) => { setValue('impactStatement', newImpactStatement); isTextValid.impactStatement(newImpactStatement); }}
              />
              {errors.impactStatement && (
                <div className={styles.formError}>{errors.impactStatement}</div>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.formRow}>
            <div className={styles.richTextEditorWrapper}>
              <label htmlFor="additional-details" className="bx--label">Additional details (optional) {hiddenIcon}</label>
              <RichTextEditor
                className={`${styles.richTextEditor} ${errors.additionalDetails ? styles.invalid : ''}`}
                data={values.additionalDetails}
                placeholder="Any details here will be visible only to you and IBM"
                onChange={(newAdditionalDetails) => { setValue('additionalDetails', newAdditionalDetails); isTextValid.additionalDetails(newAdditionalDetails); }}
              />
              {errors.additionalDetails && (
                <div className={styles.formError}>{errors.additionalDetails}</div>
              )}
            </div>
          </div>
        )}
        <div className={styles.formRow}>
          <label htmlFor="file-upload" className={`${styles.label} bx--label`}>Attach a file to this idea (optional)</label>
          <FileUpload
            selectedFiles={selectedFiles}
            updateSelectedFiles={setSelectedFiles}
          />
          {errors.fileUpload && (
            <p className={styles.formError}>{errors.fileUpload}</p>
          )}
        </div>
        {isIBMEmployee && (
          <>
            <div className={styles.formRow}>
              <FormGroup legendText="Blocker tags (optional)">
                <div className={styles.formRowCheckbox}>
                  <Checkbox
                    id="blocker-to-sell"
                    labelText="Blocker to Sell (Pre Sales)"
                    checked={values.blockerToSell}
                    onChange={(value: boolean) => {
                      setValues((previousValues) => ({
                        ...previousValues,
                        blockerToSell: value
                      }));
                    }}
                  />
                </div>
                <div className={styles.formRowCheckbox}>
                  <Checkbox
                    id="blocker-to-deploy"
                    labelText="Blocker to Deploy (Post Sales)"
                    checked={values.blockerToDeploy}
                    onChange={(value: boolean) => {
                      setValues((previousValues) => ({
                        ...previousValues,
                        blockerToDeploy: value
                      }));
                    }}
                  />
                </div>
                <div className={styles.formRowCheckbox}>
                  <Checkbox
                    id="blocker-for-digital"
                    labelText="Blocker for Digital"
                    checked={values.blockerForDigital}
                    onChange={(value: boolean) => {
                      setValues((previousValues) => ({
                        ...previousValues,
                        blockerForDigital: value
                      }));
                    }}
                  />
                </div>
              </FormGroup>
            </div>
            <div className={`${styles.formRow} ${styles.shortField}`}>
              <FilterableMultiSelect
                id="managed-tag-dropdown"
                titleText="IBM wide managed tags (optional)"
                placeholder="Add managed tags"
                itemToString={(dropdownItem) => dropdownItem}
                items={visibleManagedTags}
                onChange={({ selectedItems }) => {
                  setIBMManagedTags(selectedItems);
                }}
                selectedItems={ibmManagedTags}
              />
              <div className={styles.tagsContainer}>
                {ibmManagedTags.map((tag) => (
                  <Tag
                    key={tag}
                    name={tag}
                    colour="#DDD"
                  />
                ))}
              </div>
            </div>
            <div className={`${styles.formRow} ${styles.shortField}`}>
              <FilterableMultiSelect
                id="classification-theme-dropdown"
                titleText="Classification themes (optional)"
                placeholder="Add classification themes"
                items={classificationThemes}
                itemToString={(dropdownItem) => dropdownItem}
                selectedItems={selectedClassificationThemes}
                onChange={({ selectedItems }) => setSelectedClassificationThemes(selectedItems)}
              />
              <div className={styles.tagsContainer}>
                {selectedClassificationThemes.map((theme) => (
                  <Tag
                    key={theme}
                    name={theme}
                    colour="#DDD"
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </section>

      <section className={styles.formSection}>
        <h3>Help us to help you</h3>
        <div className={styles.formRow}>
          <RadioButtonGroup
            name="priority"
            legendText="Idea priority"
            valueSelected={values.priority}
            onChange={(value: string) => {
              setValue('priority', value);
            }}
          >
            {IDEA_PRIORITY_OPTIONS.map((priority) => (
              <RadioButton
                key={priority.id}
                labelText={priority.text}
                value={priority.id}
              />
            ))}
          </RadioButtonGroup>
        </div>
        {selectedProduct?.hide_needed_by !== true && (
          <div className={`${styles.formRow} ${styles.shortField}`}>
            <Dropdown
              id="needed-by"
              titleText="Needed by"
              label=""
              items={NEEDED_BY_OPTIONS}
              itemToString={(item) => item.text}
              selectedItem={selectedNeededBy}
              onChange={({ selectedItem }) => {
                const neededById = selectedItem?.id || '';
                setValue('neededBy', neededById);
                isNeededByValid(neededById);
              }}
              invalid={!!errors.neededBy}
              invalidText={errors.neededBy}
            />
          </div>
        )}
        <div className={`${styles.formRow} ${styles.shortField}`}>
          <TextInput
            id="support-case-id"
            name="supportCaseId"
            labelText={<>Related support case number (optional) {hiddenIcon}</>}
            placeholder="XX000000000"
            invalid={!!errors.supportCaseId}
            invalidText={errors.supportCaseId}
            required
            value={values.supportCaseId}
            onChange={onFieldChange}
            onBlur={onFieldChange}
          />
        </div>
        <div className={`${styles.formRow} ${styles.shortField}`}>
          <CompanyInput
            labelText={<>Company {hiddenIcon}</>}
            isInvalid={!!errors.company}
            onChange={(newCompanyAssociations) => {
              setAssociatedCompanies(newCompanyAssociations);
              isCompanyValid(newCompanyAssociations);
            }}
          />
          {errors.company && (
            <div className={styles.formError}>{errors.company}</div>
          )}
        </div>
      </section>

      <section className={styles.formSection}>
        <h3>Before you go</h3>
        { groups.length > 0 && (
          <div className={`${styles.formRow} ${styles.shortField}`}>
            <MultiSelect
              id="idea-group"
              titleText="Add this idea to your shared groups (optional)"
              label={getGroupLabel()}
              items={groups}
              itemToString={(group: Group) => group.name!}
              onChange={({ selectedItems }) => {
                setSelectedGroupIds(selectedItems.map((group) => group.id));
              }}
              disabled={isLoadingGroups || groups.length === 0}
            />
          </div>
        )}
        {(process.env.REACT_APP_ENABLE_ISSUE_TRACKER === 'true') && isIBMEmployee && selectedProduct?.is_in_issue_tracker && (
          <div className={styles.formRowCheckbox}>
            <Checkbox
              id="issue-tracker-candidate"
              // labelText="For product scouts: This idea is a candidate for the Issue Tracker"
              labelText={(
                <p>
                  For product scouts: This idea is a candidate for the Issue Tracker.
                  <br />
                  Learn more about the{' '}
                  <Link
                    href="https://w3.ibm.com/w3publisher/product-scout-program/#Questions"
                    target="_blank"
                  >
                    Product Scout Program
                  </Link>
                </p>
              )}
              checked={values.issueTrackerCandidate}
              onChange={(value: boolean) => {
                setValues({ ...values, issueTrackerCandidate: value });
              }}
            />
          </div>
        )}
        <div className={styles.formRowCheckbox}>
          <Checkbox
            id="subscribe"
            labelText="Subscribe to receive email updates about this idea"
            checked={values.subscribeCreator}
            onChange={(value: boolean) => {
              setValues({ ...values, subscribeCreator: value });
            }}
          />
        </div>
        <div className={styles.formRowCheckbox}>
          <Checkbox
            id="portal-terms"
            className={styles.termsCheckbox}
            labelText={(
              <>
                <p>
                  I agree to the{' '}
                  <Link
                    href="https://www.ibm.com/support/pages/node/6465213"
                    target="_blank"
                  >
                    IBM Ideas Portal Terms of Use
                  </Link>
                  <br />
                  Do not place IBM confidential, company confidential, or personal information into any field.
                </p>
                {errors.agreeToTerms && (
                  <p className={styles.formError}>{errors.agreeToTerms}</p>
                )}
              </>
            )}
            checked={values.agreeToTerms}
            onChange={(value: boolean) => {
              setValues({ ...values, agreeToTerms: value });
              isAgreeToTermsValid(value);
            }}
          />
        </div>
      </section>

      <div className={styles.formRow}>
        <ButtonSet className={styles.buttonSet}>
          {isSubmitting ? (
            <InlineLoading description="Adding idea..." status="active" />
          ) : (
            <Button onClick={onAddClick}>Add idea</Button>
          )}
        </ButtonSet>
      </div>
      {formError && (
        <InlineNotification
          kind="error"
          title="Failed to add idea"
          subtitle={formError}
          lowContrast
          hideCloseButton
        />
      )}
    </>
  );
}

export default NewIdea;
